import useSWR from "swr";
import { getCookie } from "cookies-next";
import ListingGeoCard from "../cards/ListingGeoCard";
import Heading from "../heading/Heading";
import fetcher from "utils/fetcher";

const ExploreNearby = ({
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
}) => {
  const cookie = getCookie("geo_location");
  const coords = cookie ? cookie.split(",") : null;
  if (coords === null || (coords[0] === "0" && coords[1] === "0")) {
    return null;
  }

  const {
    data,
    error,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useSWR(
    `${process.env.NEXT_PUBLIC_LISTINGS_API_URL}/homepage?lat=${coords[0]}&lng=${coords[1]}`,
    fetcher
  );

  if (error || !data) {
    return null;
  }

  return (
    <div className="relative">
      <Heading
        desc="Ontdek geweldige accommodaties bij u in de buurt"
        isCenter={true}
      >
        Ontdek de omgeving
      </Heading>
      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
        {data?.geolocationResults.map((item, i) => (
          <ListingGeoCard key={item.city + i} listing={item} />
        ))}
      </div>
    </div>
  );
};

export default ExploreNearby;
