import Link from "next/link";
import NcImage from "../shared/NcImage";
import convertNumbThousand from "utils/convertNumbThousand";

const CardCategory5 = ({ className = "", taxonomy }) => {
  const { count, name, href = "/", thumbnail } = taxonomy;
  return (
    <Link href={href} data-nc-id="CardCategory5" legacyBehavior>
      <a className={`nc-CardCategory5 flex flex-col ${className}`}>
        <div
          className={
            "group aspect-w-4 aspect-h-3 relative h-0 w-full flex-shrink-0 overflow-hidden rounded-2xl"
          }
        >
          <NcImage
            src={thumbnail}
            className="h-full w-full rounded-2xl object-cover"
          />
          <span className="absolute inset-0 bg-black bg-opacity-10 opacity-0 transition-opacity group-hover:opacity-100" />
        </div>
        <div className="mt-4 truncate px-3">
          <h2
            className={
              "truncate text-base font-medium text-neutral-900 dark:text-neutral-100 sm:text-lg"
            }
          >
            {name}
          </h2>
          <span
            className={
              "mt-2 block text-sm text-neutral-6000 dark:text-neutral-400"
            }
          >
            {convertNumbThousand(count)} accommodaties
          </span>
        </div>
      </a>
    </Link>
  );
};

export default CardCategory5;
