export const FEATURED_REGIONS = [
  {
    id: "1",
    href: "/vakantiehuizen?region=wad&location=Waddeneilanden",
    name: "De Waddeneilanden",
    taxonomy: "regions",
    count: "150+",
    thumbnail:
      "/assets/img/homepage-featured-regions/topvakantiehuizen-waddeneilanden.jpg",
  },
  {
    id: "222",
    href: "/vakantiehuizen?region=dren&location=Drenthe",
    name: "Drenthe",
    taxonomy: "regions",
    count: "100+",
    thumbnail:
      "/assets/img/homepage-featured-regions/topvakantiehuizen-drenthe.jpg",
  },
  {
    id: "3",
    href: "/vakantiehuizen?region=limb&location=Limburg",
    name: "Zuid-Limburg",
    taxonomy: "regions",
    count: "45+",
    thumbnail:
      "/assets/img/homepage-featured-regions/topvakantiehuizen-zuid-limburg.jpg",
  },
  {
    id: "4",
    href: "/vakantiehuizen?region=zeel&location=Zeeland",
    name: "Zeeland",
    taxonomy: "regions",
    count: "485+",
    thumbnail:
      "/assets/img/homepage-featured-regions/topvakantiehuizen-zeeland.jpg",
  },
  {
    id: "5",
    href: "/vakantiehuizen?lat=52.14697&location=Veluwe&lon=5.87769",
    name: "De veluwe, Arnhem",
    taxonomy: "regions",
    count: "150+",
    thumbnail:
      "/assets/img/homepage-featured-regions/topvakantiehuizen-veluwe.jpg",
  },
];
