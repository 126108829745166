import Image from "next/image";
import Link from "next/link";

const ListingGeoCard = ({ className = "", listing }) => {
  const { count, country, region, city, image } = listing;

  return (
    <Link
      className={`nc-CardCategoryBox1 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] relative flex items-center p-3 sm:p-6 ${className}`}
      href={`/vakantiehuizen?country=${country}&region=${region}&city=${city}`}
    >
      <div className="relative h-24 w-24 flex-shrink-0 overflow-hidden rounded-full">
        <div className="mx-auto">
          <Image
            src={image.url}
            alt={`${count} accommodaties gevonden in ${city}, ${country}`}
            layout="fixed"
            className="mx-auto"
            width={170.6}
            height={113.75}
          />
        </div>
      </div>
      <div className="ml-4 flex-grow overflow-hidden">
        <h2 className="text-base font-medium">
          <span className="line-clamp-1">{city}</span>
        </h2>
        <span
          className={
            "mt-2 block text-sm text-neutral-500 dark:text-neutral-400"
          }
        >
          {count} accommodaties
        </span>
      </div>
    </Link>
  );
};

export default ListingGeoCard;
