const fetcher = async (...args) => {
  const response = await fetch(...args);

  if (response.ok) {
    return response.json();
  }
  throw new Error(`${response.status} ${response.statusText}`);
};

export default fetcher;
