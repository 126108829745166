import React, { useEffect } from "react";
import Glide from "@glidejs/glide";
import Heading from "../heading/Heading";
import CardCategory3 from "../cards/CardCategory3";
import CardCategory4 from "../cards/CardCategory4";
import CardCategory5 from "../cards/CardCategory5";
import { FEATURED_REGIONS } from "../../data/featured-regions";
import NextPrev from "../shared/NextPrev";

const FeaturedItemsSlider = ({
  uniqueId,
  heading = "Heading of sections",
  subHeading = "Descriptions for sections",
  className = "",
  itemClassName = "",
  countries = FEATURED_REGIONS,
  itemPerRow = 5,
  categoryCardType = "card3",
  sliderStyle = "alignLeft",
}) => {
  const UNIQUE_CLASS = `glide_${uniqueId}`;

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      new Glide(`.${UNIQUE_CLASS}`, {
        perView: itemPerRow,
        gap: 32,
        bound: true,
        breakpoints: {
          1280: {
            perView: itemPerRow - 1,
          },
          1024: {
            gap: 20,
            perView: itemPerRow - 1,
          },
          768: {
            gap: 20,
            perView: itemPerRow - 2,
          },
          640: {
            gap: 20,
            perView: itemPerRow - 3,
          },
          500: {
            gap: 20,
            perView: 1.3,
          },
        },
      }).mount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RenderCard = ({ item }) => {
    switch (categoryCardType) {
      case "card1":
        return <CardCategory3 taxonomy={item} />;
      case "card2":
        return <CardCategory4 taxonomy={item} />;
      case "card3":
        return <CardCategory5 taxonomy={item} />;
      default:
        return <CardCategory3 taxonomy={item} />;
    }
  };

  return (
    <div className={`nc-SectionSliderNewCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading
          desc={subHeading}
          hasNextPrev={sliderStyle === "alignLeft"}
          isCenter={sliderStyle === "alignCenter"}
        >
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {countries.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                <RenderCard item={item} />
              </li>
            ))}
          </ul>
        </div>

        {sliderStyle === "alignCenter" && (
          <NextPrev className="mt-16 justify-center" />
        )}
      </div>
    </div>
  );
};

export default FeaturedItemsSlider;
